import React from 'react'

const Icon = ({src, alt}) => {
    return (
            <img className="sm:w-10 sm:h-10 w-8 h-8" src={src} alt={alt} />
    )
}
export const IconLink = ({to, src, alt}) => {
    return (
        <a className="transform hover:rotate-12" href={to} target="_blank" rel="noopener noreferrer" >
            <Icon src={src} alt={alt} />
        </a>
    )
}

export default Icon
